const SignUpStyleObject = (theme) => {
  const { palette, custom, breakpoints } = theme;
  return {
    secondaryBtn: {
      backgroundColor: palette.secondary.main,
      color: palette.text.textSecondaryColor,
      border: "1px solid" + palette.border.secondaryBorder,
      padding: "8px 20px ",
      marginLeft: "5px ",
      marginTop: "0px",
      borderRadius: "25px ",
      "&:hover": {
        backgroundColor: palette.hoverSecondaryColor.main,
        color: palette.text.hoverTextSecondaryColor,
        border: "1px solid " + palette.border.hoverSecondaryBorder,
      },
    },
    backButton: {
      fontWeight: 600,
      fontFamily: "'Source Sans Pro', sans-serif",
      textDecoration: "none",
      fontSize: "13px !important",
      letterSpacing: "0.46px",
      lineHeight: "22px",
      py: "4px",
      color: theme.palette.primary.main,
      [breakpoints.down("md")]: { py: "0px" },
    },
    successDialogOK: {
      backgroundColor: "#12b512",
      borderRadius: "25px ",
      fontSize: "12px !important",
      color: "#fff !important",
      padding: "10px ",
      width: "80px",
    },
    main: {
      backgroundColor: "#fff",
    },
    primaryBtn: {
      color: palette.primary.contrastText,
      backgroundColor: palette.primary.main,
      border: "1px solid " + palette.border.primaryBorder,
      width: "100%",
      height: "42px",
      fontWeight: 600,
      lineHeight: "26px",
      fontFamily: "'Source Sans Pro', sans-serif",
      borderRadius: "100px ",
      padding: "0",
      marginTop: "-10px ",
      "&:hover": {
        backgroundColor: palette.hoverPrimaryColor.main,
        color: palette.text.hoverTextPrimaryColor,
      },
      "&:disabled": {
        color: palette.text.disable,
        backgroundColor: palette.background.disable,
        borderColor: palette.background.disable,
      },
    },
    spinner: {
      position: "absolute",
      zIndex: "1",
      margin: "auto",
      color: palette.primary.main,
    },
    cardFooter: {
      justifyContent: "center",
      pt: "16px",
      pb: "0px",
    },
    state: {
      [`& p`]: {
        left: "0px",
      },
    },
    TermsCondition: {
      "& .tc": {
        color: palette.primary.main,
        fontFamily: "'Source Sans Pro', 'sans-serif'",
        backgroundColor: "transparent ",
        border: "none ",
        textAlign: "left ",
        cursor: "pointer",
        letterSpacing: ".17px",
        lineHeight: "20.02px",
        textDecoration: "underline",
      },
    },
    headerSection: {
      color: palette.text.labelPrimaryColor,
      fontFamily: "'Source Sans Pro', 'sans-serif'",
      display: "grid",
      gap: "8px",
      padding: "0",
      [breakpoints.down("md")]: { padding: "0 8px" },
    },
    text1: {
      letterSpacing: "0.15px",
      fontFamily: "Source Sans Pro",
      lineHeight: "24px",
      fontSize: "16px !important",
      [breakpoints.down("md")]: {
        color: palette.text.textPrimaryColor,
      },
    },
    title: {
      fontFamily: "Source Sans Pro",
      lineHeight: "2rem",
      color: palette.text.textPrimaryMainColor,
      [breakpoints.down("md")]: { color: palette.text.textPrimaryColor },
    },
    hippa: {
      boxShadow: "4px 0px 10px 0px #0000000D",
      border: "1px solid #EAECF0",
      background:
        "linear-gradient(0deg, #ffffff, #ffffff), linear-gradient(0deg, #eaecf0, #eaecf0)",
      borderRadius: "4px",
      p: "16px 24px 24px 24px",
      "& p": { m: "0px" },
      fontFamily: "Source Sans Pro",
      color: `${palette.text.textSecondaryColorNew}`,
      textAlign: "center",
      [breakpoints.down("md")]: { marginBottom: "54px" },
    },
    authImageSection: {
      height: "100vh",
      backgroundImage: `url( ${require(`../../assets/images/others/${
        custom.DesktopBGImage || "DesktopBgDC.png"
      }`)})`,
      backgroundRepeat: "round",
      backgroundSize: "cover",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      "& .logoImageDesktop": {
        position: "absolute",
        width: "23%",
      },
      [breakpoints.down("md")]: {
        height: "320px",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url( ${require(`../../assets/images/others/${
          custom.loginBGImage || "DCBg.png"
        }`)})`,
        display: "block",
        "& .logoImageDesktop": {
          display: "none !important",
        },
      },
    },
    authFormSection: {
      height: "100vh",
      overflowY: "auto",
      paddingTop: "56px",
      display: "flex",
      flexDirection: "column",
      ".authFormBox": {
        width: "62.5%",
        margin: "auto",
        padding: "0px",
      },
      [breakpoints.down("md")]: {
        position: "absolute",
        top: "64px",
        paddingTop: "24px",
        ".authFormBox": { minWidth: "328px" },
      },
    },
    formCard: {
      boxShadow:
        "0px 6px 38px -23px #192A554D, 0px 6px 38px -31px #192A5533, 0px 8px 15px -31px #192A554D ",
      borderRadius: "8px",
      padding: "24px",
      border: `1px solid #F3F4F6`,
      marginTop: "24px",
      "& .MuiAlert-standard": {
        marginBottom: "24px",
      },
    },
    appBar: {
      padding: "5px 10px",
      backgroundColor: "transparent !important",
      boxShadow: "none",
    },
    navBar: {
      height: "64px",
      width: "100%",
      display: "none",
      borderBottom: "1px solid #eaecf01a",
      [breakpoints.down("md")]: { display: "block" },
      "& img": {
        padding: "0 16px",
      },
    },
  };
};
export { SignUpStyleObject };
