import React, { useState, useEffect } from "react";
import axios from "axios";
import queryString from "query-string";
import { Link, Navigate } from "react-router-dom";
import { Alert, InputAdornment, Button } from "@mui/material";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Analytics from "@aws-amplify/analytics";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import Card from "../../components/Card/Card.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import CardFooter from "../../components/Card/CardFooter.jsx";
import WithUser from "../../ContextAndHOC/WithUser";
import { Formik, Form, Field } from "formik";
import { string, object } from "yup";
import FormikTextField from "../../components/FormikValidatedComponents/TextField";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import ErrorDialog from "../../components/ErrorDialog";
import { detect } from "detect-browser";
import Spinner from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import { API_MOUNT_PATH } from "../../constants";
import { withRouter } from "../../ContextAndHOC/WithRouter";
import Box from "@mui/material/Box";
import { getRoleBaseRedirectPath, getUserDetailObject } from "./utils";
import LoginCss from "../../components/CSS/LoginCss.jsx";
import { withMediaQuery } from "../../components/CustomInput/CustomMaterialUIWithWidth";
import { CommonStyleObject } from "../../components/CSS/CommonCss";
import { Typography } from "@mui/material";
import SuccessDialog from "../../components/SuccessDialog";
import PropTypes from "prop-types";

const browser = detect();
function LoginPage(props) {
  const [state, setState] = useState({
    renderto: "",
    loginSucess: false,
    userError: "",
    forceRedirect: false,
    country: "",
    invalidEmail: false,
    ieStyle: false,
    blockIeUser: false,
    isLoginCompleted: false,
    email: "",
    showDialog: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const { theme, t } = props;
  const classes = { ...CommonStyleObject(theme), ...LoginCss(theme) };

  const yupSchema = object().shape({
    email: string()
      .required(t("common.COMMON__FIELD_REQUIRED_MESSAGE"))
      .email(t("login.L__LOGIN__LOGIN_EMAIL_ERROR")),
    password: string().required(t("common.COMMON__FIELD_REQUIRED_MESSAGE")),
  });
  const handleSubmit = async (values, formikFunc) => {
    setState((state) => ({ ...state, isLoginCompleted: true }));
    await axios
      .post(API_MOUNT_PATH + "/providerauthservice/login", {
        username: values.email,
        password: values.password,
      })
      .then(async (response) => {
        let { redirectTo } = queryString.parse(props.location.search);
        if (response.data.status === 1) {
          localStorage.setItem("refreshToken", response.data.refreshToken);
          if (!response.data.userrole) {
            await props.currentUser.refreshTokens();
            setState((state) => ({
              ...state,
              renderto: "/firsttimelogin",
              forceRedirect: true,
              email: values.email,
            }));
            props.currentUser.changeUser({
              country: response.data.country,
            });
          } else {
            if (redirectTo) {
              setState((state) => ({ ...state, renderto: redirectTo }));
            } else {
              setState((state) => ({
                ...state,
                renderto: getRoleBaseRedirectPath(response.data.userrole),
              }));
            }
            await props.currentUser.refreshTokens();
            const userObject = getUserDetailObject(response.data, props.t);
            props.currentUser.changeUser(userObject);
            await Analytics.updateEndpoint({
              userAttributes: {
                role: [response.data.userrole],
                uID: [response.data.userid],
              },
            });
          }
          setState((state) => ({ ...state, loginSucess: true }));
        } else if (response.data.status === 0) {
          setState((state) => ({
            ...state,
            userError: response.data.warningmessage,
            isLoginCompleted: false,
          }));
          formikFunc.setSubmitting(false);
        }
      })
      .catch((err) => {
        formikFunc.setSubmitting(false);
        if (Object.assign({}, err.response).status === 422) {
          setState((state) => ({
            ...state,
            invalidEmail: true,
            isLoginCompleted: false,
          }));
        }
      });
  };
  const handleSignup = () => {
    props.navigate("/signup");
  };
  const handleInvalidEmail = () => {
    setState((state) => ({
      ...state,
      invalidEmail: false,
      isLoginCompleted: false,
    }));
  };
  const navigationDetails = async () => {
    if (browser.name === "ie") {
      setState((state) => ({ ...state, blockIeUser: true }));
    }
    let response = await axios.get(
      API_MOUNT_PATH + "/providerauthservice/getdeploymentcountry",
      null
    );
    setState((state) => ({ ...state, country: response.data }));
    if (browser.name === "ie" && response.data === "co") {
      setState((state) => ({ ...state, ieStyle: true }));
    }
    if (localStorage.getItem("refreshToken")) {
      let result = await axios({
        method: "post",
        url: API_MOUNT_PATH + "/providerauthservice/accessTokenByRefreshToken",
        data: { refreshToken: localStorage.getItem("refreshToken") },
      });
      if (result.status === 200) {
        localStorage.setItem("accessToken", result.data.accessToken);
        localStorage.setItem("refreshToken", result.data.refreshToken);
        if (result.data.role === 1) {
          props.navigate(`../../superadmin`);
        } else if (result.data.role === 2 || result.data.userrole === 5) {
          props.navigate(`../../admin`);
        } else if (result.data.role === 3) {
          props.navigate("../../provider");
        } else if (result.data.role === 6) {
          props.navigate(`../../firsttimelogin`);
        } else if (result.data.role === 4) {
          props.navigate(`../../coworker`);
        } else if (result.data.role === 7) {
          props.navigate(`../../claimadmin`);
        }
      }
    }
  };
  useEffect(() => {
    navigationDetails();
    if (
      ["UAT", "RELEASEQA", "DCRELEASEQA"].includes(
        theme.staticContent.environment
      )
    ) {
      setState((state) => ({ ...state, showDialog: true }));
    }
    // eslint-disable-next-line
  }, []);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleChange = () => {
    setState((state) => ({ ...state, userError: "" }));
  };
  const dismissDialog = () => {
    setState({ showDialog: false });
  };
  let invalidEmailButton = [
    <Button sx={classes.invalidEmailBtn} onClick={handleInvalidEmail}>
      {t("prescribe.OK")}
    </Button>,
  ];
  let HeaderText = props.t("login.L__EMAIL_NOT_REGISTERED");
  let BrowserNotCompatibleText = (
    <p style={{ whiteSpace: "pre-line" }}>
      {t("login.L__BROWSER_NOT_COMPATIBLE_TEXT")}
    </p>
  );
  const redirectButtons = [
    <Button
      sx={[classes.newSecondaryBtn, classes.buttoncss]}
      onClick={() => dismissDialog()}
    >
      {t("common.COMMON_CONTINUE_DEMO_ENV")}
    </Button>,
    <Button sx={[classes.newSecondaryBtn, classes.buttoncss]}>
      <a
        href={
          theme.staticContent.releaseDomain !== ""
            ? t("config.ReleaseUrl")
            : "#"
        }
        style={{ color: "#1B5BD9", textDecoration: "none" }}
      >
        {t("common.COMMON_HCP_PORTAL")}
      </a>
    </Button>,
  ];
  const loginCompleted =
    (props.currentUser.userDetails.isAuthenticated && state.loginSucess) ||
    state.forceRedirect;
  return (
    <div>
      <Helmet>
        <title>{t("login.L__LOGIN_TITLE_TEXT")}</title>
      </Helmet>
      {state.showDialog === true && (
        <SuccessDialog
          successButton={redirectButtons}
          HeaderText={props.t("common.COMMON_ENVIRONMENT_TEXT")}
          BodyText={
            <Typography sx={classes.bannerBody}>
              {props.t("common.COMMON_DOSECHECK_HCP_ENV")}
            </Typography>
          }
        />
      )}
      {loginCompleted ? (
        <Navigate
          to={state.renderto}
          replace={true}
          state={{ email: state.email }}
        />
      ) : (
        <>
          <Formik
            initialValues={{ email: "", password: "" }}
            onSubmit={handleSubmit}
            validationSchema={yupSchema}
          >
            {(props) => (
              <Form>
                <Box sx={classes.outsideGap}>
                  <Typography variant="h5" sx={classes.loginTitle}>
                    {t("common.COMMON__LOGIN")}
                  </Typography>
                  <Typography variant="body1" sx={classes.loginDescription}>
                    {t("login.L__LOGIN__LOGIN_Description")}
                  </Typography>
                  <Card sx={classes.cardShadow}>
                    {state.blockIeUser ? (
                      <ErrorDialog
                        HeaderText={BrowserNotCompatibleText}
                        blockIEpopup={true}
                      />
                    ) : (
                      ""
                    )}
                    <CardBody sx={{ px: "24px", pt: "24px", pb: "0px" }}>
                      {state.userError && (
                        <Box sx={{ p: "0px 0px 24px 0px" }}>
                          <Alert severity="error" style={classes.pwdError}>
                            {state.userError}
                          </Alert>
                        </Box>
                      )}
                      <Field
                        component={FormikTextField}
                        label={t("login.L__LOGIN__EMAIL")}
                        id="email"
                        onChange={handleChange}
                        name="email"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          type: "email",
                          endAdornment: (
                            <InputAdornment position="end">
                              <FontAwesomeIcon
                                icon={faUser}
                                style={classes.userLogo}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Box
                        sx={state.ieStyle ? classes.ieWidth : classes.fullwidth}
                      >
                        <Field
                          component={FormikTextField}
                          label={t("login.L__LOGIN__PASSOWRD")}
                          id="password"
                          sx={[{ mt: "3px" }]}
                          name="password"
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            type: showPassword ? "text" : "password",
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button
                                  sx={classes.eyeLayout}
                                  onClick={handleShowPassword}
                                >
                                  <FontAwesomeIcon
                                    icon={showPassword ? faEyeSlash : faEye}
                                    style={classes.icnColor}
                                  />
                                </Button>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <Link
                        to={"/guest/ForgetPassword"}
                        className="aTagFont"
                        style={classes.forgotcss}
                      >
                        {t("common.COMMON__FORGOT_ PASSWORD")}
                      </Link>
                    </CardBody>
                    <CardFooter
                      sx={classes.cardFooter}
                      disabled={props.isSubmitting}
                      style={{ justifyContent: "center" }}
                    >
                      <GridContainer>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          sx={{ px: "9px" }}
                        >
                          <Button
                            type="submit"
                            sx={[classes.primaryBtn, classes.btn]}
                            size="small"
                            disabled={state.isLoginCompleted}
                          >
                            {state.isLoginCompleted && (
                              <Spinner
                                style={{
                                  position: "absolute",
                                  zIndex: "1",
                                  margin: "auto",
                                  top: "50%",
                                  left: "50%",
                                  translate: "-50% -50%",
                                }}
                                size={20}
                              />
                            )}
                            {t("login.L__LOGIN__LOGIN_BUTTON")}
                          </Button>
                        </GridItem>
                        {theme.staticContent.selfSignUp && (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              sx={{ px: "9px" }}
                            >
                              <Divider
                                sx={classes.dividerStyle}
                                className={"orFont"}
                              >
                                {t("login.L__LOGIN__OR")}
                              </Divider>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              sx={{ px: "9px" }}
                            >
                              <Button
                                component={Link}
                                to={"/Signup"}
                                sx={[classes.secondaryBtn, classes.btn]}
                              >
                                {t("signup.S__SIGNUP__SIGN_UP_BUTTON")}
                              </Button>
                            </GridItem>
                          </>
                        )}
                      </GridContainer>
                    </CardFooter>
                    {state.invalidEmail ? (
                      <ErrorDialog
                        successButton={invalidEmailButton}
                        HeaderText={HeaderText}
                        dismiss={handleInvalidEmail}
                      />
                    ) : (
                      ""
                    )}
                  </Card>
                </Box>
              </Form>
            )}
          </Formik>
        </>
      )}
    </div>
  );
}
LoginPage.propTypes = {
  props: PropTypes.object.isRequired,
  t: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};
export default withTranslation()(
  withMediaQuery()(WithUser(withRouter(LoginPage)))
);
