import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faEnvelope } from "@fortawesome/free-solid-svg-icons";
const styles = (theme) => {
  return {
    clrWhite: {
      color: theme.palette.text.unauthFontClr,
    },
    maxWidth: {
      maxWidth: "950px",
      margin: "0 auto",
    },
    fntSze36: {
      fontSize: "36px",
      marginBottom: "8px",
    },
    fntSze30: {
      margin: "0",
      fontSize: "30px",
    },
    primaryLink: {
      padding: "10px 16px",
      fontSize: "18px",
      lineHeight: "1.3333333",
      borderRadius: "6px",
      backgroundColor: theme.palette.primary.main,
      textDecoration: "none",
      color: "#fff",
      marginRight: "5px",
    },
    SecodaryLink: {
      padding: "10px 16px",
      fontSize: "18px",
      lineHeight: "1.3333333",
      borderRadius: "6px",
      backgroundColor: "#fff",
      border: "1px solid #515974",
      textDecoration: "none",
      color: theme.palette.primary.main,
      marginRight: "5px",
    },
  };
};
class UnableToLogin extends React.Component {
  render() {
    const { t, classes } = this.props;
    return (
      <div
        key={this.props.i18n.language} //to make sure that page is re initialised if language is changed
        style={{
          backgroundImage:
            "url(" +
            require("../../assets/images/others/" +
              this.props.theme.custom.loginBgImage) +
            ")",
          backgroundRepeat: "repeat-x",
          paddingTop: "6%",
          paddingBottom: "15%",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.maxWidth}>
          <h1 className={classes.clrWhite + " " + classes.fntSze36}>
            {t("404.404__PAGE__OOPS")}
          </h1>
          <h1 className={classes.clrWhite + " " + classes.fntSze30}>
            {t("404.404__PAGE__PAGE_NOT_FOUND")}
          </h1>
          <p className={classes.clrWhite}>
            {t("404.404__PAGE__SORRY_MESSAGE")}
          </p>
          <Link
            to={{ pathname: `/guest/login` }}
            className={classes.primaryLink}
          >
            <FontAwesomeIcon icon={faHome} /> Take Me Home
          </Link>
          <a
            href="mailto:support@isageapp.com"
            className={classes.SecodaryLink}
          >
            <FontAwesomeIcon icon={faEnvelope} /> Contact Support
          </a>
        </div>
      </div>
    );
  }
}

export default withTranslation()(UnableToLogin);
// export default UnableToLogin;
