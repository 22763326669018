import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import Grid from "@mui/material/Grid";

function GridContainer({ ...props }) {
  const { children, sx } = props;
  const grid = {
    // width: "auto"
  };
  return (
    <Grid container {...props} sx={[grid, ...(Array.isArray(sx) ? sx : [sx])]}>
      {children}
    </Grid>
  );
}

GridContainer.defaultProps = {
  sx: {},
};

GridContainer.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
};

export default GridContainer;
