import { css } from "@emotion/react";
import color from "./CommonEnum1";
const ResetPasswordStyleObject = (theme) => {
  return {
    mainLayout: {
      p: "23.29% 18.75% 20.16% 18.75%",
      [theme.breakpoints.up("xl")]: { p: "16.25% 23.75% 14.4% 23.75%" },
      [theme.breakpoints.down("md")]: { p: "0% 17% 11.1% 17%" },
      [theme.breakpoints.down("sm")]: { p: "0% 0% 2.4%  0%" },
    },
    outSideLayout: {
      p: "28.13% 18.75%;",
      [theme.breakpoints.up("xl")]: { p: "16.25% 23.75% 14.4% 23.75%" },
      [theme.breakpoints.down("md")]: { p: "0% 17% 11.1% 17%" },
      [theme.breakpoints.down("sm")]: { p: "0% 0% 7.8% 0%" },
    },
    linkBtn: {
      fontWeight: 600,
      fontFamily: "'Source Sans Pro', sans-serif",
      textDecoration: "none !important",
      pt: "28px",
      fontSize: "13px !important",
      letterSpacing: "0.46px",
      display: "flex",
      justifyContent: "center",
      lineHeight: "22px",
      color: theme.palette.primary.main,
    },
    btn: {
      width: "100%",
      lineHeight: "26px",
    },
    success: {
      fontSize: "16px !important",
      letterSpacing: ".15px",
      lineHeight: "24px",
    },
    eyeHeight: { height: "0px", maxHeight: "0px" },
    eyeIconCss: {
      color: color.eyeIconColor,
      width: "18.3px",
      height: "14.75px",
    },
    eyeIconCss1: {
      color: color.eyeIconColor1,
      width: "18.3px",
      height: "14.75px",
    },
    eyeIconButton: {
      display: "flex",
      justifyContent: "end",
      p: "0px",
      minWidth: "10px",
    },
    padAll: { padding: "9px", borderRadius: "8px" },
    errorBtn: {
      color: theme.palette.text.textPrimaryColor,
      backgroundColor: theme.palette.primary.main,
      border: "1px solid " + theme.palette.border.primaryBorder,
      borderRadius: "50px",
      margin: "8px 0",
      textAlign: "right",
      padding: "6px 17px",
      fontWeight: 400,
      lineHeight: "1.42857143",
      "&:hover": {
        backgroundColor: theme.palette.hoverPrimaryColor.main,
        color: theme.palette.text.hoverTextPrimaryColor,
        border: "1px solid " + theme.palette.border.hoverPrimaryBorder,
      },
    },
    TermsCondition: {
      fontFamily: "'Source Sans Pro', sans-serif",
      color: "#1a8eeb",
      textDecoration: "underline",
      justifyContent: "left",
      backgroundColor: "transparent",
      border: "none",
      textAlign: "left",
      cursor: "pointer",
      padding: "0px",
      fontWeight: 400,
      letterSpacing: ".17px",
      whiteSpace: "normal",
      "&:hover": {
        backgroundColor: "transparent",
        textDecoration: "underline",
      },
    },
    fntSz_16: {
      padding: "0 3px 0 0",
      color: theme.palette.text.textSecondaryColorNew,
      fontFamily: "'Source Sans Pro', sans-serif",
      letterSpacing: ".17px",
    },
    forgetTitle: { pb: "4px", [theme.breakpoints.down("md")]: { pb: "8px" } },
    setPasswordTitle: {
      color: theme.palette.text.textPrimaryMainColor,
      fontFamily: "'Source Sans Pro', sans-serif",
      [theme.breakpoints.down("md")]: {
        pb: "14px",
        fontWeight: 600,
        color: theme.palette.text.textPrimaryColor,
      },
      pb: "8px",
    },
    dis: {
      fontSize: "16px !important",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: ".15px",
    },
    helperText: css({
      color: "#646D87",
      fontSize: "12px !important",
      lineHeight: "14px",
      letterSpacing: "0.4px",
      paddingBottom: "24px",
    }),
    loginSuccess: {
      fontSize: "18px !important",
      fontWeight: 500,
      marginBottom: "15px",
    },
    logoSize: {
      width: theme.palette.logo.logoSize,
    },
    cardFootercss: { pt: "0px" },
    ackText: css({
      fontFamily: "'Source Sans Pro', sans-serif",
      color: theme.palette.text.textSecondaryColorNew,
      letterSpacing: " 0.25px",
      lineHeight: "125%",
    }),
    linkcss: css({
      fontSize: "13px !important",
      fontFamily: "'Source Sans Pro', sans-serif",
      textDecoration: "none",
      textAlign: "center",
      fontWeight: 600,
      color: theme.palette.primary.main,
      lineHeight: "22px",
      justifyContent: "center",
      display: "flex",
      paddingTop: "20px",
      paddingBottom: "13px",
    }),
    invalidtoken: {
      letterSpacing: "0.17px",
      backgroundColor: color.errorBGColor,
      color: color.errorTextColor,
    },
    buttonProgress1: {
      position: "relative",
      left: "50%",
      my: "80px",
      [theme.breakpoints.down("sm")]: {
        left: "45%",
        color: theme.palette.text.textPrimaryColor,
      },
    },
  };
};
export default ResetPasswordStyleObject;
