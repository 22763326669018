import React from "react";
import { TextField, FormHelperText } from "@mui/material";
import { getIn } from "formik";

export default function ({
  field,
  form,
  name,
  lastnumber,
  helperText,
  error,
  onChange,
  onBlur,
  displayErrorMessage = true,
  ...otherProps
}) {
  const changeHandler = (event) => {
    form.setFieldValue(field.name, event.target.value);
    if (onChange) {
      onChange(event);
    }
  };
  const blurHandler = (event) => {
    if (
      lastnumber &&
      event.target.value.charAt(event.target.value.length - 1) === "."
    ) {
      form.setFieldValue(field.name, event.target.value.slice(0, -1));
    }
    form.setFieldTouched(field.name);
    if (onBlur) {
      onBlur(event);
    }
  };
  const touch = getIn(form.touched, field.name);
  const errorText = getIn(form.errors, field.name);

  return (
    <>
      <TextField
        name={field.name}
        value={field.value}
        onChange={changeHandler}
        onBlur={blurHandler}
        {...otherProps}
        error={touch && errorText ? true : false}
      />
      {displayErrorMessage ? (
        <FormHelperText error={Boolean(touch && errorText)}>
          {(touch && errorText) || helperText}
        </FormHelperText>
      ) : (
        helperText && <FormHelperText>{helperText}</FormHelperText>
      )}
    </>
  );
}
