import React from "react";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";

export default function ErrorPage() {
  return (
    <Typography variant="h3">
      An error occured in this application. Please{" "}
      <Link to={"/guest/Login"}> login </Link> again.
    </Typography>
  );
}
