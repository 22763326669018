/** @jsxImportSource @emotion/react */
import React from "react";
import axios from "axios";
import queryString from "query-string";
import {
  Alert,
  Button,
  Typography,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import Card from "../../components/Card/Card.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import CardFooter from "../../components/Card/CardFooter.jsx";
import WithUser from "../../ContextAndHOC/WithUser";
import { Formik, Form, Field } from "formik";
import { string, object, boolean, ref } from "yup";
import FormikTextField from "../../components/FormikValidatedComponents/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import {
  GuestLoginSignUpPasswordCommonCss,
  CommonStyleObject,
} from "../../components/CSS/CommonCss";
import { withTranslation } from "react-i18next";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import { Link, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { withRouter } from "../../ContextAndHOC/WithRouter";
import FormikCheckBox from "../../components/FormikValidatedComponents/CheckBox";
import { API_MOUNT_PATH } from "../../constants";
import { withMediaQuery } from "../../components/CustomInput/CustomMaterialUIWithWidth";
import Box from "@mui/material/Box";
import ResetPasswordStyleObject from "../../components/CSS/ResetPassWordStyle.jsx";
import Analytics from "@aws-amplify/analytics";
import { getUserDetailObject, getRoleBaseRedirectPath } from "./utils.js";
import { SignUpStyleObject } from "../../components/CSS/SignUPPageStyle.jsx";
import {
  handleTermCondition,
  handleuserguide,
} from "../../components/CommonFunction";

let hostURL = window.location.host.includes("localhost");
class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.formikValidation = object().shape({
      password: string()
        .required(t("common.COMMON__FIELD_REQUIRED_MESSAGE"))
        .min(8, t("common.COMMON__PASSWORD_GUIDE"))
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#_?&^+=-])[A-Za-z\d$@$!%*#_?&^+=-]{8,}$/,
          t("common.COMMON__SET_PASSWORD_CONDITIONS")
        ),
      cnfpassword: string()
        .required(t("common.COMMON__FIELD_REQUIRED_MESSAGE"))
        .oneOf([ref("password")], t("common.COMMON__EQUAL_TO_PASSWORD")),
      acceptTerms: boolean().required(""),
      gdprDataSharing: boolean().when("isGdpr", {
        is: true,
        then: boolean(),
      }),
    });

    this.state = {
      renderto: "",
      resetPassworSuccess: true,
      userError: "",
      loding: true,
      invalidToken: "",
      termsCondition: false,
      language: this.props.i18n.language,
      country: "",
      loginSucess: false,
      isLoginCompleted: false,
      passwordShown: "password",
      passwordShownRecheck: "password",
    };
  }
  componentDidMount = async () => {
    window.scrollTo(0, 0);
    const parsed = await queryString.parse(this.props.location.search);
    // const state = await this.props.location.state;
    let token = { token: parsed.reset_token };
    let result = await axios.post(
      API_MOUNT_PATH + "/providerauthservice/checkfirsttimelogin",
      token
    );
    if (result.data.status === 2) {
      this.props.navigate("/guest/ForgetPassword", { state: "error" });
    } else if (result.data.status === 1) {
      this.setState({ termsCondition: true, country: result.data.country });
    }
    this.setState({ loding: false });
  };
  handlePassword = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
    if (this.state.userError) {
      this.setState({ userError: "" });
    }
  };

  handleSubmit = async (values, formikFunc) => {
    const parsed = queryString.parse(this.props.location.search);
    const isFromSignUp = this.props.location?.state?.is_from_signup;
    let data = {
      token: parsed.reset_token,
      signup: parsed.signup,
      password: values.password,
      cnfpassword: values.cnfpassword,
      acceptTerms: values.acceptTerms,
    };
    if (this.state.termsCondition && values.isGdpr) {
      data.dataConsentFlag = values.gdprDataSharing;
    }
    axios
      .post(
        API_MOUNT_PATH +
          `/providerauthservice/${
            isFromSignUp ? "setuppassword" : "resetpassword"
          }`,
        data
      )
      .then(async (response) => {
        if (response.data.status === 1) {
          this.setState({ isLoginCompleted: true });

          localStorage.setItem("refreshToken", response.data.refreshToken);
          if (!response.data.userrole) {
            await this.props.currentUser.refreshTokens();
            this.setState({ renderto: "/firsttimelogin", forceRedirect: true });
            this.props.currentUser.changeUser({
              country: response.data.country,
            });
          } else {
            this.setState({
              renderto: getRoleBaseRedirectPath(response.data.userrole),
            });
            await this.props.currentUser.refreshTokens();
            const userObject = getUserDetailObject(response.data, this.props.t);
            this.props.currentUser.changeUser(userObject);
            await Analytics.updateEndpoint({
              userAttributes: {
                role: [response.data.userrole],
                uID: [response.data.userid],
              },
            });
          }
          this.setState({ loginSucess: true });
        } else {
          this.setState({
            invalidToken: this.props.t("common.COMMON__MISSING_RESET_TOKEN"),
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  //handle dismissDialog
  dismissDialog = () => {
    this.setState({ resetPassworSuccess: true });
  };
  handleShowHidePassword = () => {
    if (this.state.passwordShown === "password") {
      this.setState({ passwordShown: "text" });
    } else {
      this.setState({ passwordShown: "password" });
    }
  };
  handleShowHidePasswordRecheck = () => {
    if (this.state.passwordShownRecheck === "password") {
      this.setState({ passwordShownRecheck: "text" });
    } else {
      this.setState({ passwordShownRecheck: "password" });
    }
  };
  handleRedirect() {
    this.props.navigate(this.state.renderto);
  }
  render() {
    const { theme, t } = this.props;
    const classes = {
      ...ResetPasswordStyleObject(theme),
      ...GuestLoginSignUpPasswordCommonCss(theme),
      ...CommonStyleObject(theme),
    };
    const primaryButtonClass = {
      ...SignUpStyleObject(theme).primaryBtn,
      marginTop: "20px",
      width: "100%",
      padding: "8px",
    };
    const cardBodyClasses = {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      p: "24px",
    };
    const alertClasses = {
      width: "100%",
      letterSpacing: ".15px",
      lineHeight: "24px",
      fontSize: "16px !important",
    };
    const loginCompleted =
      (this.props.currentUser.userDetails.isAuthenticated &&
        this.state.loginSucess) ||
      this.state.forceRedirect;
    const User = this.props;
    return (
      <div>
        <Helmet>
          <title>{t("login.L__LOGIN_RESET_TITLE_TEXT")}</title>
        </Helmet>
        {!this.state.loding ? (
          <GridContainer sx={classes.mainLayout}>
            {loginCompleted && this.state.termsCondition ? (
              <Navigate to={this.state.renderto} replace={true} />
            ) : (
              <>
                {loginCompleted && !this.state.termsCondition ? (
                  <>
                    <Typography variant="h5" sx={classes.setPasswordTitle}>
                      {t("common.COMMON_RESET_PASSWORD_TITLE")}
                    </Typography>
                    <Card sx={classes.cardShadow}>
                      <CardBody sx={cardBodyClasses}>
                        <Alert severity="success" sx={alertClasses}>
                          {t("common.COMMON__PASSWORD_CHANGED_SUCCESSFULLY")}
                        </Alert>
                        <Button
                          sx={primaryButtonClass}
                          onClick={() => this.handleRedirect()}
                        >
                          {this.props.t("common.COMMON__CONTINUE")}
                        </Button>
                      </CardBody>
                    </Card>
                  </>
                ) : (
                  <Formik
                    initialValues={{
                      password: "",
                      cnfpassword: "",
                      acceptTerms: false,
                      privacyPolicy: false,
                      gdprDataSharing: false,
                      isGdpr:
                        this.props.theme.staticContent.IsGDPRContentDataSharing,
                    }}
                    onSubmit={this.handleSubmit}
                    validationSchema={this.formikValidation}
                  >
                    {(props) => (
                      <Form style={{ width: "100%" }}>
                        <Typography variant="h5" sx={classes.setPasswordTitle}>
                          {t("common.COMMON__SET_NEW_PASSWORD")}
                        </Typography>
                        <Card sx={classes.padAll}>
                          {(this.state.invalidToken ||
                            this.state.userError) && (
                            <Box sx={{ p: "15px 15px 9px 15px" }}>
                              <Alert severity="error" sx={classes.invalidtoken}>
                                {this.state.invalidToken ||
                                  this.state.userError}
                              </Alert>
                            </Box>
                          )}
                          <CardBody sx={{ pb: "0px" }}>
                            <Field
                              component={FormikTextField}
                              label={t("common.COMMON__NEW_PASSWORD")}
                              name="password"
                              onChange={this.handlePassword}
                              fullWidth
                              inputProps={{ maxLength: 20 }}
                              InputProps={{
                                endAdornment: (
                                  <Button
                                    onClick={this.handleShowHidePassword}
                                    sx={classes.eyeIconButton}
                                  >
                                    <InputAdornment
                                      position="end"
                                      sx={classes.eyeHeight}
                                    >
                                      <FontAwesomeIcon
                                        style={classes.eyeIconCss}
                                        icon={
                                          this.state.passwordShown ===
                                          "password"
                                            ? faEye
                                            : faEyeSlash
                                        }
                                      />
                                    </InputAdornment>
                                  </Button>
                                ),
                                type: this.state.passwordShown,
                              }}
                            />
                            <Field
                              component={FormikTextField}
                              label={t("common.COMMON__CONFIRM_NEW_PASSWORD")}
                              fullWidth
                              name="cnfpassword"
                              onChange={this.handlePassword}
                              inputProps={{ maxLength: 20 }}
                              InputProps={{
                                endAdornment: (
                                  <Button
                                    onClick={this.handleShowHidePasswordRecheck}
                                    sx={classes.eyeIconButton}
                                  >
                                    <InputAdornment
                                      position="end"
                                      sx={classes.eyeHeight}
                                    >
                                      <FontAwesomeIcon
                                        style={classes.eyeIconCss1}
                                        icon={
                                          this.state.passwordShownRecheck ===
                                          "password"
                                            ? faEye
                                            : faEyeSlash
                                        }
                                      />
                                    </InputAdornment>
                                  </Button>
                                ),
                                type: this.state.passwordShownRecheck,
                              }}
                            />
                            {this.state.termsCondition && (
                              <>
                                <Field
                                  name="acceptTerms"
                                  component={FormikCheckBox}
                                  checkboxProps={{ color: "primary" }}
                                  label={
                                    <Box component="div">
                                      <Typography sx={classes.fntSz_16}>
                                        {t(
                                          "common.COMMON__LOGIN_TERMS_AND_CONDITION"
                                        )}
                                      </Typography>
                                      <Button
                                        type="button"
                                        sx={classes.TermsCondition}
                                        onClick={() =>
                                          handleTermCondition("", hostURL)
                                        }
                                      >
                                        {t(
                                          "common.COMMON__TERMS_AND_CONDITIONS"
                                        )}
                                      </Button>
                                    </Box>
                                  }
                                />
                              </>
                            )}
                            {this.state.termsCondition &&
                              theme.staticContent.IsGDPRContentDataSharing && (
                                <Field
                                  name="gdprDataSharing"
                                  component={FormikCheckBox}
                                  checkboxProps={{ color: "primary" }}
                                  label={
                                    <Box component="span" sx={classes.fntSz_16}>
                                      {t(
                                        "common.COMMON__GDPR_CONSENT_DESCRIPTION"
                                      )}
                                    </Box>
                                  }
                                />
                              )}
                            {this.state.termsCondition && (
                              <GridContainer>
                                <GridItem
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  sx={{ px: "0px" }}
                                >
                                  <Field
                                    name="privacyPolicy"
                                    component={FormikCheckBox}
                                    checkboxProps={{ color: "primary" }}
                                    label={
                                      <div>
                                        <Box
                                          component="span"
                                          sx={classes.fntSz_16}
                                        >
                                          {t("common.COMMON__ACKNOWLEDGE_TEXT")}
                                        </Box>
                                        <br />
                                        <Button
                                          type="button"
                                          sx={classes.TermsCondition}
                                          onClick={() =>
                                            handleTermCondition("/pp", hostURL)
                                          }
                                        >
                                          {t(
                                            "common.COMMON__AMALGAM__NOVO_PRIVACY_POLICY"
                                          )}
                                        </Button>
                                        <br />
                                        <Button
                                          type="button"
                                          sx={classes.TermsCondition}
                                          onClick={() =>
                                            handleuserguide(User, hostURL)
                                          }
                                        >
                                          {t("common.COMMON__USER_GUIDE_LINK")}
                                        </Button>
                                      </div>
                                    }
                                  />
                                </GridItem>
                              </GridContainer>
                            )}
                          </CardBody>
                          <CardFooter
                            sx={classes.cardFootercss}
                            disabled={props.isSubmitting}
                          >
                            {" "}
                            <GridContainer>
                              <GridItem
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                sx={{ pt: "20px", px: "0px" }}
                              >
                                <Button
                                  type="submit"
                                  sx={[
                                    classes.primaryBtn,
                                    classes.btn,
                                    { width: "100%" },
                                  ]}
                                  size="small"
                                  disabled={
                                    (this.state.termsCondition &&
                                      (!props.values.acceptTerms ||
                                        !props.values.privacyPolicy)) ||
                                    this.state.isLoginCompleted
                                  }
                                >
                                  {t("common.COMMON__CONFIRM_BUTTON")}
                                </Button>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={12} lg={12}>
                                <Box
                                  component={Link}
                                  to={"/guest/Login/"}
                                  css={classes.linkcss}
                                  onClick={this.logout}
                                >
                                  {t("common.COMMON__LOGOUT_LINK")}
                                </Box>
                              </GridItem>
                            </GridContainer>
                          </CardFooter>
                        </Card>
                      </Form>
                    )}
                  </Formik>
                )}
              </>
            )}
          </GridContainer>
        ) : (
          <CircularProgress size={36} sx={classes.buttonProgress1} />
        )}
      </div>
    );
  }
}

export default withTranslation()(
  withMediaQuery()(withRouter(WithUser(ResetPassword)))
);
