import React from "react";
import axios from "axios";
import WithUser from "../../ContextAndHOC/WithUser";
import { withTranslation } from "react-i18next";
import queryString from "query-string";
import { Navigate } from "react-router-dom";
import { API_MOUNT_PATH } from "../../constants";

class AutoLogin extends React.Component {
  state = {
    renderto: "",
  };
  componentDidMount = async () => {
    let response = await axios.get(
      API_MOUNT_PATH + "/providerservice/getUserDetails"
    );
    let { redirectTo } = queryString.parse(this.props.location.search);
    let observationType;
    if (response.data.observationType !== undefined) {
      for (let i = 0; i < response.data.observationType.length; i++) {
        if (response.data.observationType[i].type === 3) {
          observationType = response.data.observationType[i].uom;
        }
      }
    }
    response.data.glucoseUnit =
      observationType !== undefined && observationType[0] === 1
        ? this.props.t("common.COMMON__MG_DL")
        : this.props.t("common.COMMON__MMOL_L");
    response.data.glucoseUom =
      observationType !== undefined && observationType[0];
    this.changeUser(response.data);

    if (redirectTo) {
      this.setState({
        renderto: redirectTo.toString().replace("$token", "?token"),
      });
    }
  };
  render() {
    return (
      <div>
        {this.state.renderto ? <Navigate to={this.state.renderto} /> : ""}
      </div>
    );
  }
}

export default withTranslation()(WithUser(AutoLogin));
