const SuccessDialogCss = (theme) => ({
  headerText: {
    p: "16px 24px",
    letterSpacing: ".15px",
    fontWeight: 600,
    color: theme.palette.text.textPrimaryMainColor,
    width: "444px",
    [theme.breakpoints.down("sm")]: { width: "100%" },
  },
  bodyText: {
    color: theme.palette.text.textSecondaryColorNew,
    p: "8px 24px",
    letterSpacing: ".15px",
    fontSize: "16px !important",
  },
  errorHeaderText: {
    fontSize: "24px !important",
    lineHeight: "32.02px",
    textAlign: "center",
    marginBottom: "24px",
    fontFamily: "Source Sans Pro",
  },
  errorBodyText: {
    fontSize: "16px !important",
    letterSpacing: ".15px",
    lineHeight: "24px",
    textAlign: "center",
    marginBottom: "24px",
    fontFamily: "Source Sans Pro",
  },
  errorExclamation: {
    fontSize: "48px !important",
    lineHeight: "48px",
    textAlign: "center",
  },
});
export default SuccessDialogCss;
