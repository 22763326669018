const cardStyle = {
  card: {
    marginBottom: "30px",
    marginTop: "10px",
    // color: ,
    background: "#fff",
    width: "100%",
    borderRadius: "8px",
    border: "1px solid #EAECF0",
    boxShadow:
      "0px 6px 38px -23px rgba(25, 42, 85, 0.30), 0px 6px 38px -31px rgba(25, 42, 85, 0.20), 0px 8px 15px -31px rgba(25, 42, 85, 0.30)",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    minWidth: "0",
    wordWrap: "break-word",
    fontSize: ".875rem",
    transition: "all 300ms linear",
  },
  cardPlain: {
    background: "transparent",
    boxShadow: "none",
  },
  cardCarousel: {
    overflow: "hidden",
  },
};

export default cardStyle;
