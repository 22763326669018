import React, { memo } from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import Login from "./LoginPage";
import AutoLogin from "./AutoLogin";
import UnhandledError from "./UnhandledError";
import ForgetPassword from "./ForgetPassword";
import ResetPassword from "./ResetPassword";
import { withTranslation } from "react-i18next";
import { withMediaQuery } from "../../components/CustomInput/CustomMaterialUIWithWidth";
import Box from "@mui/material/Box";
import VerifyEmailId from "./VerifyEmailId";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import { GuestLoginSignUpPasswordCommonCss } from "../../components/CSS/CommonCss";

const PublicPage = memo(function PublicPage(props) {
  const { theme, width, t } = props;
  const classes = GuestLoginSignUpPasswordCommonCss(theme, width);
  const pathname = window.location.pathname;
  const RouterCall = (yes) => {
    return (
      <>
        <Routes>
          <Route path={"ResetPassword"} element={<ResetPassword />} />
          <Route path={"ForgetPassword"} element={<ForgetPassword />} />
          <Route path={"Login"} element={<Login />} />
          <Route path={"UnhandledError"} element={<UnhandledError />} />
          <Route path={"AutoLogin"} element={<AutoLogin />} />
          <Route index element={<Navigate to={"Login"} />} />
          <Route path={"VerifyEmail"} element={<VerifyEmailId />} />
        </Routes>
      </>
    );
  };

  return (
    <GridContainer sx={classes.bgImg}>
      <GridItem md={6} xs={12} sx={classes.authImageSection}>
        <img
          src={`${require("../../assets/images/others/" +
            theme.custom.loginLogoDesktop)}`}
          alt="Logo"
          className="logoImageDesktop"
        />

        <Box sx={classes.navBar}>
          <img
            src={`${require("../../assets/images/others/" +
              theme.custom.loginLogo)}`}
            alt="Logo"
          />
        </Box>
      </GridItem>
      <GridItem xs={12} sm={12} md={6} lg={6} sx={[classes.test, { p: "0px" }]}>
        {RouterCall()}
      </GridItem>
    </GridContainer>
  );
});

export default withTranslation()(withMediaQuery()(PublicPage));
