import React from "react";
// @material-ui/core components
import Grid from "@mui/material/Grid";

function GridItem({ ...props }) {
  const { children, sx } = props;
  const grid = {
    position: "relative",
    width: "100%",
    minHeight: "1px",
    paddingRight: "15px",
    paddingLeft: "15px",
    flexBasis: "auto",
  };
  return (
    <Grid item {...props} sx={[grid, ...(Array.isArray(sx) ? sx : [sx])]}>
      {children}
    </Grid>
  );
}

export default GridItem;
