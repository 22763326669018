import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

// core components
import cardFooterStyle from "../../assets/jss/components/cardFooterStyle.jsx";

function CardFooter({ ...props }) {
  const { sx, children, ...rest } = props;
  return (
    <Box
      component="div"
      className="CardFooter"
      sx={[cardFooterStyle.cardFooter, ...(Array.isArray(sx) ? sx : [sx])]}
      {...rest}
    >
      {children}
    </Box>
  );
}

CardFooter.propTypes = {
  sx: PropTypes.object || PropTypes.array,
};

export default CardFooter;
