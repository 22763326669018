import { createTheme } from "@mui/material/styles";
import { common } from "@mui/material/colors";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import createCache from "@emotion/cache";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faExclamationCircle,
  faCheckCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { detect } from "detect-browser";
// const jssvalue = create({ plugins: [...jssPreset().plugins, rtl()] });
// const cacheRtl = createCache({
//   key: "mui-style-rtl",
//   stylisPlugins: [prefixer, rtlPlugin],
// });

// const cacheLtr = createCache({
//   key: "mui-style-ltr",
// });
const fontSize = { fontSize: "16px !important" };
const fontSize12 = { fontSize: "12px !important" };
const browser = detect();
const browserName = browser.name;
export default function newTheme(theme) {
  const isRtl = theme.RTLJustification;
  const label = {
    ...fontSize12,
    lineHeight: "19.92px",
    letterSpacing: "0.4px",
    color: theme.textPrimaryMainColor,
  };
  const fourhundred = 400;
  return createTheme({
    // jss:jssvalue,
    Rtl: isRtl,
    direction: isRtl ? "rtl" : "ltr",
    staticContent: {
      termAndConditonUrl:
        "https://" + theme.resourcePrefix + theme.domain + "/documents/",
      studyAbstractUrl:
        "https://" +
        theme.resourcePrefix +
        theme.domain +
        "/documents/studyAbstract/",
      environment: theme.environment,
      releaseDomain: theme.ReleaseUrl,
      domain: theme.domain,
      selfSignUp: theme.SelfSignUp,
      IsGDPRContentDataSharing: theme.IsGDPRContentDataSharing,
      IsbothBguomSupported: theme.bothBguomSupported,
      onboardEmailCampaignEnabled: theme.onboardEmailCampaignEnabled,
    },
    custom: {
      DesktopBGImage: theme.DesktopBGImage,
      loginBgImage: theme.loginBGImage,
      loginLogo: theme.loginLogo,
      loginLogoDesktop: theme.loginLogoDesktop,
      navbarImage: theme.navbarImage,
      navbarImageBg: theme.navbarImageBg,
      navbarImageMobile: theme.navbarImageMobile,
      activeNavLink: theme.activeNavLink,
      printDetailLogo: theme.printDetailLogo,
      printDetailLink: theme.printDetail,
      printDetailsQRCode: theme.printDetailsQRCode,
      printDetailsPhoneImage: theme.printDetailsPhoneImage,
      profilePic: theme.profilePic,
      crmimage: theme.crmimage,
      defaultDoctorPic: theme.defaultDoctorPic,
      productTitle: theme.title,
    },
    termsAndCondition: {
      termsAndConditionVersion: theme.termsAndConditionVersion,
    },
    palette: {
      common: {
        black: "#000",
        white: "#fff",
        contrastText: common.white,
      },
      background: {
        paper: "#fff",
        default: "#fff",
        contrastText: common.white,
        success: theme.successBackgroundColor,
        disable: theme.backgroundDisabledColor,
      },
      secondary: {
        main: theme.secondaryColor,
        contrastText: common.white,
        prescribeBtn: theme.prescribeBtnColor,
        onBoardingBtnColor: theme.onBoardingBtnColor,
      },
      primary: {
        contrastText: common.white,
        dark: theme.darkPrimaryColor,
        main: theme.primaryColor,
      },
      success: {
        main: theme.successColor,
      },
      info: {
        main: theme.infoColor,
      },
      thirdColor: {
        main: theme.thirdColor,
      },
      fourthColor: {
        main: theme.fourthColor,
      },
      hoverPrimaryColor: {
        main: theme.hoverPrimaryColor,
      },
      hoverSecondaryColor: {
        main: theme.hoverSecondaryColor,
      },
      hoverThirdColor: {
        main: theme.hoverThirdColor,
        contrastText: common.white,
      },
      hoverFourthColor: {
        main: theme.hoverFourthColor,
        contrastText: common.white,
      },
      border: {
        primaryBorder: theme.primaryBorder,
        secondaryBorder: theme.secondaryBorder,
        thirdBorder: theme.thirdBorder,
        fourthBorder: theme.fourthBorder,
        hoverPrimaryBorder: theme.hoverPrimaryBorder,
        hoverSecondaryBorder: theme.hoverSecondaryBorder,
        hoverThirdBorder: theme.hoverThirdBorder,
        hoverFourthBorder: theme.hoverFourthBorder,
        contrastText: common.white,
      },
      error: {
        light: "#e57373",
        main: "#D32F2F",
        dark: "#d32f2f",
        contrastText: common.white,
      },
      text: {
        textPrimaryColor: theme.textPrimaryColor,
        textSecondaryColor: theme.textSecondaryColor,
        textSecondaryColorNew: theme.textSecondaryColorNew,
        textThirdColor: theme.textThirdColor,
        textFourthColor: theme.textFourthColor,
        hoverTextPrimaryColor: theme.hoverTextPrimaryColor,
        hoverTextSecondaryColor: theme.hoverTextSecondaryColor,
        hoverTextThirdColor: theme.hoverTextThirdColor,
        hoverTextFourthColor: theme.hoverTextFourthColor,
        primary: "#515974",
        mainFontColor: theme.mainFontColor,
        labelPrimaryColor: theme.labelPrimaryColor,
        mainInputColor: theme.mainInputColor,
        mainLabelColor: theme.mainLabelColor,
        secondary: "#515974",
        third: "#fff",
        disabled: theme.textDisabledColor,
        inputTextDisableColor: theme.inputTextDisableColor,
        hint: "rgba(0, 0, 0, 0.38)",
        title: theme.titleClr,
        unauthFontClr: theme.unauthFontClr,
        printButtonColor: theme.printButtonColor,
        studyTopHeading: theme.studyTopHeading,
        studyHeading: theme.studyHeading,
        studyRowOne: theme.studyRowOne,
        studyRowTwo: theme.studyRowTwo,
        contrastText: common.white,
        btntextcolor: theme.btntextcolor,
        WarnTextTitle: theme.WarnTextTitle,
        textPrimaryMainColor: theme.textPrimaryMainColor,
      },
      minWidthMenu: {
        minWidthMenu: theme.minWidthMenu,
        contrastText: common.white,
      },
      logbook: {
        logbookFasting: theme.logbookFasting,
        logbookNonFasting: theme.logbookNonFasting,
        logbookInsulin: theme.logbookInsulin,
        viewcoworker: theme.viewcoworker,
        contrastText: common.white,
      },
      graph: {
        bgcolor: theme.bgcolor,
        med1GraphStartColor: theme.med1GraphStartColor,
        med1Color: theme.med1Color,
        med1GraphEndColor: theme.med1GraphEndColor,
        contrastText: common.white,
      },
      logo: {
        logoSize: theme.logoSize,
      },
      divider: "rgba(0, 0, 0, 0.12)",
      spacing: 8,
    },
    typography: {
      useNextVariants: true,
      fontFamily: "'Source Sans Pro','Roboto', 'Helvicta','Arial',sans-serif",
      button: {
        textTransform: "inherit",
      },
    },
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            fontFamily: "'Roboto', 'Helvicta','Arial',sans-serif",
            padding: "16.5px 12px",
            color: `${theme.textPrimaryMainColor}!important`,
            ...fontSize,
          },
          root: {
            "&.Mui-disabled": {
              color: `${theme.inputTextDisableColor}`,
              "& p": {
                color: `${theme.inputTextDisableColor}`,
              },
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontFamily: "'Roboto', 'Helvicta','Arial',sans-serif",
            color: `${theme.textSecondaryColorNew}`,
            ...fontSize,
          },
          shrink: {
            ...fontSize12,
            top: "3px",
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            color: `${theme.textPrimaryMainColor}`,
            ...fontSize,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontSize: "15px !important",
            letterSpacing: "0.46px",
            fontWeight: 600,
          },
        },
      },
      MUIDataTableFilter: {
        styleOverrides: {
          checkboxFormControlLabel: {
            marginLeft: "0px",
            paddingLeft: "9px",
            paddingRight: "9px",
            lineHeight: "24px",
            letterSpacing: "0.15px",
            color: `${theme.textSecondaryColorNew} !important`,
          },
          checkbox: {
            paddingLeft: "25px !important",
            color: `${theme.textSecondaryColorNew} !important`,
          },
          checkboxListTitle: {
            textAlign: "inherit",
            ...fontSize,
            color: theme.textSecondaryColorNew,
            lineHeight: "24px",
            fontWeight: `${fourhundred} !important`,
            letterSpacing: "0.15px",
            paddingBottom: "4px",
            "& p": fontSize,
          },
          reset: {
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            "& button": {
              fontSize: "13px !important",
              fontWeight: 600,
              lineHeight: "22px",
              letterSpacing: "0.46px",
              paddingTop: "0px",
            },
          },
          resetLink: {
            marginLeft: "0px",
            ...fontSize,
            paddingBottom: "10px",
            paddingTop: "0px",
          },
          title: {
            fontSize: "18px !important",
            color: theme.textSecondaryColorNew,
            lineHeight: "22.63px",
            fontWeight: fourhundred,
          },
          root: {
            padding: "16px",
            "& div:nth-child(2)": {
              div: {
                maxWidth: "100%",
                flexBasis: "100%",
                display: "inline-block",
              },
            },
          },
        },
      },
      MUIDataTableViewCol: {
        styleOverrides: {
          title: {
            textAlign: "inherit",
            ...fontSize,
          },
          label: { "& p": fontSize },
        },
      },
      MUIDataTable: {
        styleOverrides: {
          responsiveScroll: {
            "max-height": "100%",
          },
          caption: {
            display: "none",
          },
        },
      },

      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            color: "#001C61 !important",
            fontWeight: `${500} !important`,
            fontFamily: "'Roboto', sans-serif",
            padding: "16px !important",
            backgroundColor: "#F9FAFB !important",
            borderBottom: "inherit",
          },
          toolButton: {
            maxWidth: browserName === "ie" ? 185 : "100%",
            marginRight: isRtl ? "-8px !important" : "8px !important",
            paddingTop: "0px",
            paddingBottom: "0px",
          },
          data: {
            color: theme.textPrimaryMainColor,
            letterSpacing: ".17px",
            fontWeight: `${500} !important`,
            fontFamily: "'Roboto', sans-serif",
            lineHeight: "24px",
            fontSize: "14px !important",
          },
        },
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            paddingTop: "11.6px !important",
            paddingBottom: "11.6px !important",
          },
        },
      },
      MUIDataTableToolbar: {
        styleOverrides: {
          actions: {
            width: "5%",
            "@media (max-width:600px)": { width: "10%" },
            "& button": { padding: "12px" },
          },
          root: {
            width: "100%",
            "@media (max-width:600px)": { display: "flex" },
            color: theme.titleClr,
            padding: "24px 16px",
          },
          filterCloseIcon: { display: "none !important" },
          filterPaper: {
            borderRadius: "8px !important",
            maxWidth: "50% !important",
          },
          left: {
            width: "95%",
            "@media (max-width:600px)": { width: "90%" },
          },
          titleText: {
            color: theme.titleClr,
          },
        },
      },
      PrivateNotchedOutline: {
        styleOverrides: {
          legend: {
            width: "inherit !important",
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            whiteSpace: "nowrap",
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: "16px",
            wordWrap: "break-word",
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            "&:hover": {
              backgroundColor: "#f3f3f3",
              cursor: "pointer",
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            height: "inherit",
          },
        },
      },
      MuiGridListTile: {
        styleOverrides: {
          root: {
            width: "100% !important",
            height: "inherit !important",
          },
        },
      },
      MuiFormGroup: {
        styleOverrides: {
          root: {
            display: "inherit",
          },
        },
      },
      MUIDataTableSearch: {
        styleOverrides: {
          clearIcon: { display: "none !important" },
        },
      },
      MuiStepConnector: {
        styleOverrides: {
          root: {
            "&.Mui-active": {
              background: theme.primaryColor,
              height: "2px",
            },
            "&.Mui-completed": {
              background: theme.primaryColor,
              height: "2px",
            },
          },
        },
      },
      MuiStepIcon: {
        styleOverrides: {
          text: {
            fill: "#fff",
          },
        },
      },
      MuiStepLabel: {
        styleOverrides: {
          iconContainer: {
            paddingRight: "0px",
          },
        },
      },
      MUIDataTablePagination: {
        styleOverrides: { tableCellContainer: { padding: "0px !important" } },
      },
      MuiTablePagination: {
        styleOverrides: {
          displayedRows: label,
          root: {
            ...label,
            "& .MuiInputBase-root": { marginLeft: "0px", marginRight: "26px" },
          },
          select: { ...label },
          selectLabel: {
            color: theme.textSecondaryColorNew,
            ...fontSize12,
            lineHeight: "19.92px",
            letterSpacing: "0.4px",
          },
          "& .MuiSelect-icon": { color: "#193154" },
          actions: {
            "& button": { color: "#193154" },
            marginLeft: "26px !important",
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            minHeight: "1em",
            marginTop: "4px",
            marginBottom: "10px",
            ...fontSize12,
            letterSpacing: "0.4px",
            marginLeft: "4px",
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          standardSuccess: {
            backgroundColor: "#eaf2ea",
            color: "#383d4c",
            fontFamily: "'Source Sans Pro', sans-serif",
          },
          standardInfo: {
            backgroundColor: "#e6f3fa",
            color: "#013654",
            fontFamily: "'Source Sans Pro', sans-serif",
          },
          standardError: {
            backgroundColor: "#fbeaea",
            color: "#d32f2f",
            fontFamily: "'Source Sans Pro', sans-serif",
          },
        },
        defaultProps: {
          iconMapping: {
            success: (
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ fontSize: "18.25px", color: "#2e7d32" }}
              />
            ),
            info: (
              <FontAwesomeIcon
                icon={faInfoCircle}
                style={{ fontSize: "18.25px" }}
              />
            ),
            error: (
              <FontAwesomeIcon
                icon={faExclamationCircle}
                style={{ fontSize: "18.25px", color: "#d32f2" }}
              />
            ),
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            "&.Mui-checked": { color: `${theme.primaryColor} !important` },
          },
        },
      },
      PrivatePickersYear: {
        styleOverrides: {
          button: {
            "&.Mui-selected": {
              backgroundColor: `${theme.primaryColor} !important`,
            },
          },
        },
      },
      PrivatePickersMonth: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              backgroundColor: `${theme.primaryColor} !important`,
            },
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              backgroundColor: `${theme.primaryColor} !important`,
            },
          },
        },
      },
    },
  });
}
