import React from "react";
// nodejs library that concatenates classes
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import Box from "@mui/material/Box";
// @material-ui/icons

// core components
import cardBodyStyle from "../../assets/jss/components/cardBodyStyle.jsx";

function CardBody({ ...props }) {
  const { sx, children, ...rest } = props;
  return (
    <Box
      component="div"
      className="CardBody"
      sx={[cardBodyStyle.cardBody, ...(Array.isArray(sx) ? sx : [sx])]}
      {...rest}
    >
      {children}
    </Box>
  );
}

CardBody.propTypes = {
  sx: PropTypes.object || PropTypes.array,
};

export default CardBody;
