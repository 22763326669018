import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
export const withMediaQuery = () => (Component) => (props) => {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  const width =
    keys.reduce((output, key) => {
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || "lg";
  return <Component width={width} {...props} theme={theme} />;
};
