import React from "react";
import Spinner from "@mui/material/CircularProgress";
import queryString from "query-string";
import { withRouter } from "../ContextAndHOC/WithRouter";
class formReloader extends React.Component {
  render() {
    setTimeout(() => {
      if (this.props.location.state) {
        this.props.navigate(
          "./" +
            queryString.parse(this.props.location.search).reloadTo +
            "/" +
            this.props.location.state
        );
      } else {
        this.props.navigate(
          "./" + queryString.parse(this.props.location.search).reloadTo
        );
      }
    }, parseInt(queryString.parse(this.props.location.search).timeOut));
    return (
      <div>
        <Spinner />
      </div>
    );
  }
}
export default withRouter(formReloader);
