import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { createRoot } from "react-dom/client";
import React from "react";
import ErrorDialog from "../src/components/ErrorDialog";
// import * as serviceWorker from './serviceWorker';
import "./index.css";
import Analytics from "@aws-amplify/analytics";
import Auth from "@aws-amplify/auth";
import axios from "axios";
import {
  awsPinPointConfig,
  sessionConfig,
  pageOptions,
  initialisePinPointConfig,
} from "./PinpointConfig.js";
import { API_MOUNT_PATH } from "./constants";
import RenderAppBasedOnInternet from "./RenderAppBasedOnInternet";
const webPlatform = 3;
const ProductidDoseCheck = 2;
let getConfig = async () => {
  let result = await axios.get(
    API_MOUNT_PATH + "/providerauthservice/getconfig"
  );
  let response = await axios.get(
    API_MOUNT_PATH + "/providerauthservice/getDomain"
  );
  result.data.domain = response.data.domain;
  let pinpointCountry = result.data.country;
  let pinpointRegion =
    result.data.pinpoint[pinpointCountry].idpId.split(":")[0];
  initialisePinPointConfig(
    result.data.pinpoint[pinpointCountry].idpId,
    pinpointRegion,
    result.data.pinpoint[pinpointCountry].appId
  );
  Auth.configure(awsPinPointConfig()["Auth"]);
  Analytics.configure(awsPinPointConfig()["Analytics"]);
  Analytics.autoTrack("session", sessionConfig);
  Analytics.autoTrack("pageView", pageOptions);
  return result.data;
};
let getCountryIsSuppported = async () => {
  let appVersionResult = await axios({
    method: "get",
    url: `${API_MOUNT_PATH}/patientauthservice/appVersion`,
    headers: {
      "x-Productid": ProductidDoseCheck,
      "x-Platformid": webPlatform,
    },
  });
  return appVersionResult.data;
};
const container = document.getElementById("root");
const root = createRoot(container);
getCountryIsSuppported()
  .then(function (appDetails) {
    if (appDetails.currentReleaseVersion === "0.0.0.1") {
      root.render(
        <ErrorDialog
          successButton={""}
          HeaderText={appDetails.webAlterText}
          dismiss={""}
        />
      );
    } else {
      getConfig()
        .then(function (configObject) {
          root.render(<RenderAppBasedOnInternet configObject={configObject} />);
        })
        .catch(function (err) {
          console.log(err);
          alert("Unable to download config. Fatal error.");
        });
    }
  })
  .catch(function (err) {
    console.log(err);
    alert("Unable to download config. Fatal error.");
  });
