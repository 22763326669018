import React from "react";
import { Button, Typography, Box } from "@mui/material";
import { withTranslation } from "react-i18next";
import ErrorDialog from "./ErrorDialog";
import WithUser from "../ContextAndHOC/WithUser";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import SuccessDialogCss from "./CSS/DialogCss";
import { CommonStyleObject } from "./CSS/CommonCss";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

function TimeOutBackground(props) {
  const { t } = props;
  const theme = useTheme();
  const classes = { ...CommonStyleObject(theme), ...SuccessDialogCss(theme) };
  const loginButton = {
    width: "100%",
    p: "8px 22px",
    display: "flex",
    alignItems: "center",
    lineHeight: "26px",
  };

  let navigate = useNavigate();
  React.useEffect(() => {
    props.currentUser.changeUser();
  }, []);
  const handleClick = () => {
    navigate(`/`);
  };
  let okButton = [
    <Box component="div" sx={{ pb: "32px" }}>
      <Button sx={[classes.primaryBtn, loginButton]} onClick={handleClick}>
        {t("login.L__LOGIN__LOGIN_BUTTON")}
      </Button>
    </Box>,
  ];

  const ExclamationIcon = {
    width: "48px",
    height: "48px",
  };

  let HeaderText = [
    <Typography sx={classes.errorExclamation}>
      <ErrorOutlineOutlinedIcon sx={ExclamationIcon}></ErrorOutlineOutlinedIcon>
    </Typography>,
    <Typography sx={classes.errorHeaderText} variant="h5">
      {t("publicJs.P_PUBLIC_YOU_HAVE_BEEN_LOGGED_OUT")}
    </Typography>,
    <Typography sx={classes.errorBodyText} variant="body1">
      {t("publicJs.P_PUBLIC_SESSION_HAS_BEEN_EXPIRED")}
    </Typography>,
  ];

  const dismissDialog = () => {
    navigate(`/`);
  };

  return (
    <ErrorDialog
      css={true}
      successButton={okButton}
      HeaderText={HeaderText}
      dismiss={dismissDialog}
    />
  );
}

export default withTranslation()(WithUser(TimeOutBackground));
