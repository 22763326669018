import React from "react";
import { Dialog, Typography, DialogTitle, DialogActions } from "@mui/material";
const dialogBg = { backgroundColor: "inherit", opacity: "inherit" };
const cardBox = {
  "& .MuiPaper-root": {
    borderRadius: "8px",
    border: "1px solid #F3F4F6",
    background: "#FFF",
    boxShadow:
      "0px 6px 38px -23px rgba(25, 42, 85, 0.30), 0px 6px 38px -31px rgba(25, 42, 85, 0.20), 0px 8px 15px -31px rgba(25, 42, 85, 0.30)",
  },
};
const CustomDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle
      sx={(theme) => ({
        display: "flex",
        justifyContent: props.css ? "center" : "space-between",
        alignItems: "center",
        maxWidth: props.css && "400px",
        radius: "8px",
        border: "1px",
        width: props.css ? "inherit" : "600px",
        padding: props.css ? "40px 40px 0px 40px" : "16px",
      })}
      component="div"
      {...other}
    >
      <Typography variant="subtitle2">{children}</Typography>
    </DialogTitle>
  );
};

class SuccessDialog extends React.Component {
  state = {
    scroll: "body",
  };

  render() {
    return (
      <Dialog
        open={true}
        maxWidth="sm"
        sx={cardBox}
        BackdropProps={{
          style: dialogBg,
        }}
      >
        <CustomDialogTitle id="customized-dialog-title" css={this.props.css}>
          {this.props.HeaderText}
        </CustomDialogTitle>
        <DialogActions
          sx={
            this.props.css && {
              justifyContent: "center",
              px: "40px",
              "& div": { width: "100%" },
            }
          }
        >
          {this.props.blockIEpopup ? (
            ""
          ) : (
            <>
              {this.props.successButton &&
                this.props.successButton.map((button, i) => (
                  <div key={i}>
                    <div>{button}</div>
                  </div>
                ))}
            </>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default SuccessDialog;
