import React from "react";

const defaultUserObj = {
  name: "guest",
  isAuthenticated: false,
  role: "guest",
  permissionlevel: "",
  firstname: "",
  lastname: "",
};

const UserLoggedInContext = React.createContext({
  userDetails: defaultUserObj,
  changeUser: () => {
    //defining signature of the component
  },
  refreshTokens: () => {
    //defining signature of the component
  },
});

const UserLoggedInContextProvider = UserLoggedInContext.Provider;
const UserLoggedInContextConsumer = UserLoggedInContext.Consumer;
export {
  UserLoggedInContext as default,
  defaultUserObj,
  UserLoggedInContextProvider,
  UserLoggedInContextConsumer,
};
