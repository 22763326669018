import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  DialogTitle,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { CommonStyleObject } from "./CSS/CommonCss";

const CustomDialogTitle = (props) => {
  const { children, classes, onClose, ...others } = props;
  return (
    <DialogTitle
      sx={(theme) => ({
        margin: 0,
        display: "flex",
        justifyContent: "space-between",
        paddingTop: "16px",
        paddingLeft: "24px",
        width: "444px",
        [theme.breakpoints.down("sm")]: { width: "100%" },
      })}
      component="div"
      {...others}
    >
      <Typography variant="h6">{children}</Typography>
    </DialogTitle>
  );
};
const SuccessDialog = (props) => {
  const theme = useTheme();
  const classes = CommonStyleObject(theme);
  const css = {
    [theme.breakpoints.down("sm")]: {
      display: "block",
      float: "right",
      textAlign: "right",
      "& div": { ml: "0px !important" },
      "& button": { pl: "0px" },
    },
  };
  return (
    <Dialog
      // onClose={this.props.dismiss}
      scroll="body"
      open={true}
      aria-labelledby="customized-dialog-title"
      PaperProps={{ style: { maxWidth: 444 } }}
    >
      <CustomDialogTitle id="customized-dialog-title" onClose={props.dismiss}>
        <Typography sx={classes.header}>{props.HeaderText}</Typography>
      </CustomDialogTitle>
      <DialogContent
        sx={{
          paddingTop: "8px",
          paddingBottom: "8px",
          fontSize: "14px",
        }}
      >
        <Typography sx={classes.body}>{props.BodyText}</Typography>
      </DialogContent>
      <DialogActions sx={props.nextLine && css}>
        {props.successButton?.map((button, i) => (
          <div key={i}>
            <div>{button}</div>
          </div>
        ))}
      </DialogActions>
    </Dialog>
  );
};

export default SuccessDialog;
