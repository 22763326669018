import React, { useEffect, useState } from "react";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { API_MOUNT_PATH } from "../../constants";
import { useTranslation } from "react-i18next";
import { withMediaQuery } from "../../components/CustomInput/CustomMaterialUIWithWidth";
import WithUser from "../../ContextAndHOC/WithUser";
import { SignUpStyleObject } from "../../components/CSS/SignUPPageStyle";
import { Button, CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";

const VerifyEmailId = (props) => {
  const { theme } = props;
  const classes = SignUpStyleObject(theme);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const [isFetching, setIsFetching] = useState(true);
  const [message, setMessage] = useState({
    header: "",
    desc: "",
    isVerified: false,
    isPasswordSet: true
  });
  const [passwordStatus, setPasswordStatus] = useState({ isPasswordSet: true, token: '' })
  useEffect(() => {
    (async () => {
      try {
        let result = await axios({
          method: "post",
          url: API_MOUNT_PATH + "/providerauthservice/verifyEmail",
          headers: { "x-access-token": localStorage.getItem("accessToken") },
          data: { token: params.get("token") },
        });
        if (result?.data?.status === 1) {
          setMessage({
            header: t("signup.S__VERIFYEMAIL_SUCCESS_MESSAGE"),
            desc: t("signup.S__VERIFYEMAIL_SUCCESS_MESSAGE1").replace(
              "{EMAIL_ADDRESS}",
              ""
            ),
            isVerified: true,
          });
          setPasswordStatus({ isPasswordSet: result?.data?.isPasswordSet, token: result?.data?.token })
        } else {
          throw result;
        }
      } catch (error) {
        setMessage({
          header: t("signup.Err_Reset_Token_Invalid"),
          desc: t("signup.S__VERIFYEMAIL_FAILED_DESCRIPTION"),
          isVerified: false,
        });
        console.error("🚀 ~ file: VerifyEmailId.jsx:22 ~ error:", error);
      } finally {
        setIsFetching(false);
      }
    })();
  }, []);
  const cardBodyStyle = {
    textAlign: "center",
    height: "378px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const loaderWrapper = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "34px",
  };
  const outsideGap = {
    p: "16.25% 18.75% 14.4% 18.75%",
    [theme.breakpoints.down("md")]: { p: "0% 4.67% 11.1% 4.67%" },
    [theme.breakpoints.down("sm")]: { p: "0% 0% 2.4%  0%" },
  };
  const handleSetPassword = () => {
    navigate(`/guest/ResetPassword/?reset_token=${passwordStatus.token}`)
  }

  return (
    <Box sx={outsideGap}>
      <Card>
        <CardBody sx={cardBodyStyle}>
          {isFetching ? (
            <div style={loaderWrapper}>
              <CircularProgress />
              {t("signup.S__VERIFYING_EMAIL")}
            </div>
          ) : (
            <div style={{ width: "100%" }}>
              <h2>{message.header}</h2>
              <p>{message.desc}</p>
              <Button
                sx={{
                  ...classes.primaryBtn,
                  marginTop: "0px !important",
                  width: "40%",
                }}
                onClick={() => !passwordStatus.isPasswordSet && passwordStatus.token ? handleSetPassword() : navigate("/guest/Login")}
              >
                {!message.isVerified
                  ? t("common.COMMON__LOGIN")
                  : t("common.COMMON__CONTINUE")}
              </Button>
            </div>
          )}
        </CardBody>
      </Card>
    </Box>
  );
};

export default withMediaQuery()(WithUser(VerifyEmailId));
