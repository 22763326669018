import React from "react";
import { withMediaQuery } from "./components/CustomInput/CustomMaterialUIWithWidth";
import { withTranslation } from "react-i18next";
import { Offline, Online } from "react-detect-offline";
import ErrorDialog from "../src/components/ErrorDialog";
import App from "./App";
import Button from "@mui/material/Button";
import { POLLING_URL } from "./constants";
class RenderApp extends React.Component {
  reloadFunction = function () {
    window.location.reload();
  };

  retryButton = [<Button onClick={this.reloadFunction}>Retry</Button>];
  componentDidMount = () => {
    if (this.props.configObject.RTLJustification) {
      import("./views/Provider/saudirtl.css");
    }
  };

  render() {
    return (
      <>
        <App _config={this.props.configObject} />
        <Offline
          polling={{ url: POLLING_URL, interval: 30000, timeout: 10000 }}
        >
          <ErrorDialog
            successButton={""}
            HeaderText={this.props.configObject.connectionMessage}
            dismiss={""}
          />
        </Offline>
      </>
    );
  }
}

export default withMediaQuery()(RenderApp);
