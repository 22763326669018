import i18n from "i18next";
import backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import axios from "axios";
import { API_MOUNT_PATH } from "./constants";

const customServerlanguageDetector = {
  type: "languageDetector",
  async: true, // flags below detection to be async
  detect: (callback) => {
    return axios
      .get(API_MOUNT_PATH + "/resourceLoading/getLanguage")
      .then((response) => {
        callback(response.data.language);
        // return new Promise(function(resolve) {
        //     setTimeout(resolve,5000)
        // }).then(()=> callback('en'))
      });
  },
  init: () => {
    //defining signature of the component
  },
  cacheUserLanguage: () => {
    //defining signature of the component
  },
};

i18n
  .use(backend)
  .use(customServerlanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    debug: true,
    supportedLngs: [
      "en",
      "pt",
      "es",
      "it",
      "ar",
      "hu",
      "sk",
      "fr",
      "da",
      "fi",
      "sv",
      "de",
      "ja",
    ],
    backend: {
      loadPath: API_MOUNT_PATH + "/resourceLoading/loadLocales/{{lng}}",
      parse: function (data) {
        let parsedData = JSON.parse(data);
        console.log("parsed data :-", parsedData);
        return parsedData[Object.keys(parsedData)[0]];
      },
    },
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
