import color from "./CommonEnum1";
const LoginCss = (theme) => ({
  outsideGap: {
    p: "16.25% 18.75% 14.4% 18.75%",
    [theme.breakpoints.up("xl")]: { p: "16.25% 23.75% 14.4% 23.75%" },
    [theme.breakpoints.down("md")]: { p: "0% 17% 11.1% 17%" },
    [theme.breakpoints.down("sm")]: { p: "0% 0% 2.4%  0%" },
  },
  btn: {
    width: "100%",
  },
  pwdError: {
    backgroundColor: color.errorBGColor,
    color: color.errorTextColor,
    borderRadius: "4px",
  },
  icnColor: {
    color: color.eyeIconColor1,
    width: "18.3px",
    height: "14.24px",
    top: "4.43px",
    left: "3.3px",
  },
  userLogo: {
    color: theme.palette.text.textSecondaryColorNew,
    width: "14.1px",
    height: "16.11px",
    top: "3.88px",
    left: "4.99px",
  },
  loginTitle: {
    color: theme.palette.text.textPrimaryMainColor,
    fontFamily: "Source Sans Pro !important",
    [theme.breakpoints.down("md")]: {
      pb: "14px",
      fontWeight: 400,
      color: theme.palette.text.textPrimaryColor,
    },
    pb: "4px",
  },
  loginDescription: {
    color: theme.palette.text.textPrimaryMainColor,
    letterSpacing: ".15px",
    fontFamily: "Source Sans Pro !important",
    [theme.breakpoints.down("md")]: {
      pb: "14px",
      fontWeight: 400,
      color: theme.palette.text.textPrimaryColor,
    },
    pb: "8px",
    fontSize: "16px !important",
  },
  eyeLayout: {
    p: "0px",
    display: "flex",
    justifyContent: "right",
    minWidth: "inherit !important",
  },
  forgotcss: {
    textDecoration: "none",
    fontFamily: "Source Sans Pro",
    fontWeight: 600,
    lineHeight: "22px",
    letterSpacing: "0.46px",
    color: theme.palette.primary.main,
  },
  buttoncss: {
    letterSpacing: "0.4px",
    fontSize: "14px !important",
    wordBreak: "break-word",
    whiteSpace: "normal",
  },
  bannerBody: {
    color: theme.palette.text.textPrimaryMainColor,
    pt: "8px",
    fontSize: "16px !important",
  },
  cardFooter: { py: "24px" },
  invalidEmailBtn: {
    width: "100%",
    color: theme.palette.text.textPrimaryColor,
    backgroundColor: theme.palette.primary.main,
    border: "1px solid " + theme.palette.border.primaryBorder,
    borderRadius: "50px",
    margin: "8px 0",
    textAlign: "right",
    padding: "6px 17px",
    fontWeight: 400,
    lineHeight: "1.42857143",
    "&:hover": {
      backgroundColor: theme.palette.hoverPrimaryColor.main,
      color: theme.palette.text.hoverTextPrimaryColor,
      border: "1px solid " + theme.palette.border.hoverPrimaryBorder,
    },
  },
  ieWidth: {
    "& .MuiTextField-root": {
      width: "95%",
    },
  },
  dividerStyle: {
    py: "24px",
    fontSize: "12px !important",
    lineHeight: "14.06px",
    letterSpacing: ".4px",
  },
});
export default LoginCss;
